// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function($) {
	$('.testimonial-wrapper .flexslider').flexslider({
		animation: "fade",
		prevText: "",
		nextText: "",
		controlNav: true,
		directionNav: false,
		smoothHeight: true,
	});

}); /* end of as page load scripts */